<template>
  <page-layout ref="layout" label1="信息" label2="披露" navName="mechanism" :navData="navData">
    <router-view class="content" @loading="displayShow"></router-view>
  </page-layout>
</template>
<script>
import PageLayout from '@/components/common/PageLayout'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    PageLayout
  },
  data() {
    return {
      navData: [
        { name: '合作机构', class: 'Mechanism' },
        { name: '互联网保险', class: 'OnlineInsurance' },
        // { name: '合作网站', class: 'Website' },
        { name: '产品披露', class: 'Disclosure' },
        { name: '系统介绍', class: 'SystemIntroduction' },
      ]
    }
  },
  beforeCreate() {
    if (isMobile()) {
      document.querySelector('html').style.fontSize = '71px'
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '/') {
      var docEI = document.documentElement;
      var clientWidth = docEI.clientWidth
      docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
    }
    next();
  },
  methods: {
    displayShow(loading) {
      if (this.$refs['layout']) {
        this.$refs['layout'].displayShow(loading)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
